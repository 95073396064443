/**
 * Construct meta tag content (title, description, facebook, twitter) for standard program pages
 * Tags can be overidden on individual pages using the react-helmet package
 *
 * @module meta
 */

import React from 'react'
import urlLib from '../utils/url'

import { programs } from '../constants/constants'

const siteUrl = 'https://go.rachio.com'
const titleSuffix = 'Rachio'
const titleTemplateDefault = (text) => `${text} - ${titleSuffix}`
const titleTemplateDiscount = (program) =>
  `${program} Partnership - ${titleSuffix}`

/**
 * @typedef {Object} HeaderMetaData
 * @property {string} title
 * @property {string} description
 */

/**
 * Extract the site section from given pathname
 * Assumes pathname follow the structure of `/program-name/subpage`
 *
 * @param {string} pathname
 * @returns {Array.<String, String>} Array containing the program name and pathname
 */

function extractSiteSection(pathnameSections) {
  if (pathnameSections.length === 1) {
    return 'index'
  }

  return pathnameSections[1]
}

function constructTitleContent(section, program) {
  switch (section) {
    case 'index':
      return titleTemplateDefault(
        `Rachio Smart Sprinkler Controller for ${program.city} Residents`
      )
    case 'check':
      return titleTemplateDefault(`Check Eligibility - ${program.title}`)
    case 'eligible':
      return titleTemplateDefault(`Claim Controller - ${program.title}`)
    case 'limit':
      return titleTemplateDefault(`Limit Reached - ${program.title}`)
    case 'schedule':
      return titleTemplateDefault(`Schedule - ${program.title}`)
    case 'success':
      return titleTemplateDefault(`Success - ${program.title}`)
    case 'waitinglist':
      return titleTemplateDefault(`Join the Waitlist - ${program.title}`)
    default:
      return titleSuffix
  }
}

/**
 * Construct the title tag content
 *
 * @param {string} section
 * @param {object} program
 * @returns {string} title content for given program
 */
function constructTitle(section, program) {
  if (!program) {
    return titleSuffix
  }

  if (program.isDiscountProgram && section === 'index') {
    return titleTemplateDiscount(program.title)
  }

  return constructTitleContent(section, program)
}

/**
 * Construct the description tag content
 *
 * @param {string} section
 * @param {object} program
 * @returns {string} description content for given program
 */
function constructDescription(section, program) {
  switch (section) {
    case 'index':
      if (program && program.isDiscountProgram) {
        return `Rachio is partnering with ${program.title} to bring you the best in smart watering technology. With this exclusive discount code, enjoy $50 off your Rachio Smart Sprinkler Controller`
      }

      return `Thanks to ${program.title}, ${
        program.city || ''
      } residents will receive Rachio's premium smart watering technology! Take advantage of this limited time offer before supplies run out.`
    default:
      return ''
  }
}

function constructFacebookMetaTags(
  location,
  section,
  programKey,
  program,
  title,
  description
) {
  const { pathname, search, hash } = location
  const fullUrl = siteUrl + pathname + search + hash
  const imageLocation =
    program && program.isDiscountProgram
      ? `${siteUrl}/img/product-gen2-rachio3.png`
      : `${siteUrl}/img/${programKey}/free_rachio.png`

  switch (section) {
    case 'index':
      return [
        <meta key="og:title" property="og:title" content={title} />,
        <meta key="og:title" property="og:image" content={imageLocation} />,
        <meta key="og:title" property="og:url" content={fullUrl} />,
        <meta
          key="og:description"
          property="og:description"
          content={description}
        />,
        <meta key="og:type" property="og:type" content="website" />,
        <meta key="og:site_name" property="og:site_name" content="Rachio" />,
        <meta
          key="fb:page_id"
          property="fb:page_id"
          content="140280922827719"
        />,
      ]
    default:
      return ''
  }
}

function constructTwitterMetaTags(
  section,
  programKey,
  program,
  title,
  description
) {
  const imageLocation =
    program && program.isDiscountProgram
      ? `${siteUrl}/img/product-gen2-rachio3.png`
      : `${siteUrl}/img/${programKey}/free_rachio.png`

  switch (section) {
    case 'index':
      return [
        <meta
          key="twitter:card"
          name="twitter:card"
          content="summary_large_image"
        />,
        <meta key="twitter:site" name="twitter:site" content="@_rachio" />,
        <meta key="twitter:title" name="twitter:title" content={title} />,
        <meta
          key="twitter:description"
          name="twitter:description"
          content={description}
        />,
        <meta
          key="twitter:image:src"
          name="twitter:image:src"
          content={imageLocation}
        />,
        <meta
          key="twitter:creator"
          name="twitter:creator"
          content="@_rachio"
        />,
      ]
    default:
      return ''
  }
}

type Location = {
  pathname: string
}

function constructHeaderData(location: Location) {
  const { pathname } = location
  const pathnameSections = urlLib.listPathnameSections(pathname)
  const programKey = pathnameSections[0]
  const section = extractSiteSection(pathnameSections)
  const program = programs[programKey]

  const title = constructTitle(section, program)
  const description = constructDescription(section, program)
  const facebookMetaTags = constructFacebookMetaTags(
    location,
    section,
    programKey,
    program,
    title,
    description
  )
  const twitterMetaTags = constructTwitterMetaTags(
    section,
    programKey,
    program,
    title,
    description
  )

  return {
    title,
    description,
    facebookMetaTags,
    twitterMetaTags,
  }
}

export default constructHeaderData
