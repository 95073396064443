import ReactPixel from 'react-facebook-pixel'

export const onClientEntry = () => {
  if (process.env.ENVIRONMENT === 'production') {
    ReactPixel.init('565869436893880')
  }
}

export const onRouteUpdate = () => {
  if (process.env.ENVIRONMENT === 'production') {
    ReactPixel.pageView()
  }
}
