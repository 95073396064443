import React from 'react'
import { Helmet } from 'react-helmet'
import urlLib from '../utils/url'
import './style.css'
import SiteHeader from '../components/global/SiteHeader'
import SiteFooter from '../components/global/SiteFooter'
import metaHelpers from './meta'
import { programs } from '../constants/constants'
const oldPrograms = Object.keys(programs)
/**
 * This is a HACK!
 * Ideally we would refactor this according to the new design pattern implemented in gatsby v2
 * (@see https://www.gatsbyjs.org/docs/migrating-from-v1-to-v2/#remove-or-refactor-layout-components) but that would
 * require manually wrapping every existing page in this component and there are functional discrepancies involved
 * there
 * in addition to the work hours.  That should be done whenever we no longer need to host any of the pages built by our
 * contractor, Barry, as this pattern is clearly an abomination (which gatsby itself confirmed by their dramatic
 * decision to remove it in v2)
 *
 * As a temporary fix, we are parsing the 'obsolete-contactor-code/constants.js' file in order to find those programs
 * which are functionally dependent on its data, indicating that they are programs using the old design patterns.
 */
const OldProgramContent = ({ slug, location, children }) => {
  const isOldProgram = oldPrograms.includes(slug)
  if (isOldProgram) {
    const meta = metaHelpers(location)
    return (
      <main>
        <Helmet>
          {/* This title are overrideable via helmet in each page file */}
          {meta.title && <title>{meta.title}</title>}
          {meta.description && (
            <meta name="description" content={meta.description} />
          )}
          {meta.facebookMetaTags}
          {meta.twitterMetaTags}
        </Helmet>
        <SiteHeader partnershipLogoImages={programs[slug].logos || []} />
        {children}
        <SiteFooter />
      </main>
    )
  }
  return children
}

const IndexLayout = ({ location, children }) => {
  return (
    <OldProgramContent
      slug={urlLib.pathnameStart(location.pathname)}
      location={location}
    >
      {children}
    </OldProgramContent>
  )
}

export default IndexLayout
