import React from 'react'
import * as styles from './SiteHeader.module.css'
import { OutboundLink } from 'gatsby-plugin-google-gtag'

type Props = {
  partnershipLogoImages: Array<string> | undefined
  partnerUrl?: string
  slug?: string
  shopOpen?: boolean
  hidePartnership?: boolean
}

const ORIGIN =
  typeof window !== 'undefined'
    ? window.location.origin
    : 'https://go-rachio.com'

const PATHNAME = typeof window !== 'undefined' ? window.location.pathname : ''

const SiteHeader = ({
  partnershipLogoImages,
  partnerUrl,
  slug,
  shopOpen,
  hidePartnership,
}: Props) => (
  <nav className={`${shopOpen ? styles.navbarAlt : 'container'} ${styles.nav}`}>
    <div className="grid-x grid-margin-x">
      <div
        className="cell auto"
        style={{ display: 'flex', alignItems: 'center' }}
      >
        <a href={ORIGIN + (slug ? `/${slug}` : PATHNAME)}>
          <img className={styles.logo} src="/img/logo.png" />
        </a>
      </div>
        <div className={`cell shrink ${styles.partnershipLogos}`}>
          {partnershipLogoImages?.length && !hidePartnership ? (
            <span className={'show-for-large'}>In partnership with:</span>
          ) : null}
          <OutboundLink href={partnerUrl} target="_blank">
            {partnershipLogoImages?.map(logo => (
              <img
                key={logo + new Date().getTime()}
                className={`${styles.logo} ${styles.partnershipLogo} ${
                  partnershipLogoImages.length > 1
                    ? 'multiple-partner-nav-image'
                    : ''
                }`}
                src={logo}
              />
            ))}
          </OutboundLink>
        </div>
    </div>
  </nav>
)

export default SiteHeader
